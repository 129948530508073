<template>
  <svg
    class="heart"
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      d="M25 6.25L21.0167 18.3604C20.8128 18.9802 20.4662 19.5435 20.0049 20.0049C19.5435 20.4662 18.9802 20.8128 18.3604 21.0167L6.25 25L18.3604 28.9833C18.9802 29.1872 19.5435 29.5338 20.0049 29.9951C20.4662 30.4565 20.8128 31.0198 21.0167 31.6396L25 43.75L28.9833 31.6396C29.1872 31.0198 29.5338 30.4565 29.9951 29.9951C30.4565 29.5338 31.0198 29.1872 31.6396 28.9833L43.75 25L31.6396 21.0167C31.0198 20.8128 30.4565 20.4662 29.9951 20.0049C29.5338 19.5435 29.1872 18.9802 28.9833 18.3604L25 6.25Z"
      fill="url(#paint0_linear_5601_39368)"
      stroke="url(#paint1_linear_5601_39368)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10.416 6.25V14.5833Z" fill="url(#paint2_linear_5601_39368)" />
    <path
      d="M10.416 6.25V14.5833"
      stroke="url(#paint3_linear_5601_39368)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M39.584 35.4189V43.7523"
      stroke="url(#paint4_linear_5601_39368)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.25 10.4189H14.5833"
      stroke="url(#paint5_linear_5601_39368)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35.416 39.5811H43.7494"
      stroke="url(#paint6_linear_5601_39368)"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5601_39368"
        x1="56.25"
        y1="56.2499"
        x2="-20.3953"
        y2="47.0222"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5601_39368"
        x1="56.25"
        y1="56.2499"
        x2="-20.3953"
        y2="47.0222"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5601_39368"
        x1="11.7493"
        y1="17.3611"
        x2="9.67628"
        y2="17.3311"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5601_39368"
        x1="11.7493"
        y1="17.3611"
        x2="9.67628"
        y2="17.3311"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_5601_39368"
        x1="40.9173"
        y1="46.53"
        x2="38.8443"
        y2="46.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_5601_39368"
        x1="17.3611"
        y1="11.7523"
        x2="8.74989"
        y2="3.11274"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_5601_39368"
        x1="46.5271"
        y1="40.9144"
        x2="37.9159"
        y2="32.2748"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.258819" stop-color="#B25EFF" />
        <stop offset="0.58132" stop-color="#8E9EFF" />
        <stop offset="0.769114" stop-color="#71D2FF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "sparkles-pulse",
};
</script>

<style scoped>
.heart {
  width: 50px;
  height: 50px;
  /* For mobile screens, set width and height to 24px */
  @media (max-width: 640px) {
    width: 25px;
    height: 25px;
  }
  animation: beat 0.8s infinite alternate;
  transform-origin: center;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(0.6);
  }
}
</style>
