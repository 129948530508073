<template>
  <Splash />
</template>

<script>
import Splash from "~/components/marketing/Splash";

export default {
  name: "landing",
  transition: "fade",
  layout: "content",
  head: function () {
    return {
      title: "Spiti – Your team's video library",
      link: [
        {
          rel: "canonical",
          href: `${this.$config.appBaseUrl}`,
        },
      ],
    };
  },
  components: {
    Splash,
  },
};
</script>
