<template>
  <div class="features-box">
    <div class="flex flex-col md:flex-row">
      <div
        class="md:w-1/2 flex flex-col space-y-2 md:space-y-3 lg:space-y-4 md:space-y-6 px-3 sm:px-4 md:px-5 lg:px-6 py-6 sm:py-7 md:py-8 lg:py-9"
      >
        <div
          v-for="(feature, idx) in features"
          :key="idx"
          class="flex flex-row space-x-2 sm:space-x-3 md:space-x-4 lg:space-x-5"
        >
          <div class="w-5 flex items-center">
            <img :src="feature.icon" />
          </div>

          <div class="spiti-heading-title3 flex flex-row items-center">
            <span>{{ feature.title }}</span>
            <span
              v-if="feature.subtitle"
              class="hidden md:block font-normal text-sm opacity-60"
              >&nbsp; {{ feature.subtitle }}</span
            >
          </div>
        </div>
      </div>
      <div class="features-box-divider"></div>
      <div
        class="flex flex-grow items-center justify-center relative p-6 md:p-8"
      >
        <div class="absolute z-20 w-2/3 h-full back-ellipse"></div>
        <div
          class="flex flex-col space-y-2 sm:space-y-6 md:space-y-10 lg:space-y-14 max-w-sm p-6 md:p-0"
        >
          <div class="text-white">
            <span class="font-semibold text-5xl md:text-6xl lg:text-7xl"
              >$0</span
            >
            <span class="font-normal text-base">/user/month</span>
          </div>
          <!-- <div
            class="flex flex-col space-y-1 sm:space-y-2 md:space-y-3 lg:space-y-4"
          >
            <a
              href="https://airtable.com/app2pzU8coIiyJA2X/shrFqDoWnSMzStob0"
              target="_blank"
              rel="noopener noreferrer"
              class="spiti-btn-primary-lg"
              >Request Access</a
            >
            <span
              class="text-white text-opacity-80 font-normal text-sm text-left"
              >No credit card required</span
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          icon: require("~/assets/img/icons/infinite.svg"),
          title: "Unlimited playlists",
        },
        {
          icon: require("~/assets/img/icons/public-primary.svg"),
          title: "Public playlist",
        },
        {
          icon: require("~/assets/img/icons/spiti-connect-primary.svg"),
          title: "Spiti Connect",
        },
        {
          icon: require("~/assets/img/icons/infinite.svg"),
          title: "Unlimited team members",
        },
        {
          icon: require("~/assets/img/icons/embed-primary.svg"),
          title: "Embed in Notion, Coda & others",
        },
        {
          icon: require("~/assets/img/icons/transcription.svg"),
          title: "Auto transcriptions",
        },
        {
          icon: require("~/assets/img/icons/integrations.svg"),
          title: "Zoom, Google & Slack integrations",
          subtitle: "...and more",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.features-box {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.16) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border-radius: 20px;
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 100px 20px rgba(160, 142, 238, 0.4);
}
.features-box::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 20px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.features-box-divider {
  border-right: 1px solid;
  border-image-source: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.24) 100%
  );
  @media (max-width: 768px) {
    border-right: 0px;
    border-bottom: 1px solid;
    border-image-source: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.24) 100%
    );
  }
  -webkit-border-image-slice: 1;
  border-image-slice: 1;
}

.back-ellipse {
  background: linear-gradient(135.82deg, #a08eee 0%, #3f3472 157.78%);
  opacity: 0.3;

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-filter: blur(100px);
  filter: blur(100px);
  pointer-events: none;
  @apply rounded-full;
}
</style>
