<template>
  <div
    class="flex flex-wrap items-center justify-center gap-x-12 gap-y-9 md:gap-10 opacity-50 w-full"
  >
    <img
      src="/images/customers/nykaa.svg"
      class="h-6 md:h-8"
      alt="Nykaa logo"
    />
    <img
      src="/images/customers/deepsource.svg"
      class="h-6 md:h-8"
      alt="DeepSource logo"
    />
    <img
      src="/images/customers/fluix.svg"
      class="h-6 md:h-8"
      alt="Fluix logo"
    />
    <img
      src="/images/customers/urbanhealth.svg"
      class="h-6 md:h-8"
      alt="Urban Health logo"
    />
    <img
      src="/images/customers/last9.svg"
      class="h-6 md:h-8"
      alt="Last9 logo"
    />
    <img
      src="/images/customers/equitylist.svg"
      class="h-6 md:h-8"
      alt="Equity List logo"
    />
    <img
      src="/images/customers/gitopia.svg"
      class="h-6 md:h-8"
      alt="Gitopia logo"
    />
  </div>
</template>
<script>
export default {
  name: "CustomersList",
};
</script>
