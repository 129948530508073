<template>
  <div class="flex flex-col overflow-x-hidden">
    <div
      class="relative marketing-section border-b border-white border-opacity-15 pt-16"
    >
      <div class="z-0 pointer-events-none absolute w-full h-full">
        <div class="max-w-6xl mx-auto h-full flex flex-row justify-between">
          <div class="header-gradient-ellipse1"></div>
          <div class="header-gradient-ellipse2"></div>
        </div>
      </div>
      <div
        class="relative z-10 flex flex-col space-y-10 md:space-y-12 lg:space-y-14 py-20 md:py-28 lg:py-32 items-center text-center marketing-section-inner max-w-7xl"
      >
        <div
          class="mx-auto animate-sec1 flex flex-wrap items-center gap-1"
          style="max-width: 1174px"
        >
          <div class="spiti-heading-hero splash-header">
            The most comprehensive
            <span class="spiti-text-cerulean-blue inline-flex">AI-powered</span>
            video library for teams
          </div>
        </div>
        <div class="spiti-body-subtitle max-w-3xl mx-auto animate-sec2">
          Transform the way your team stores, shares, collaborates, and watches
          internal videos. An internal YouTube-like experience.
        </div>
        <div class="flex flex-row animate-sec3">
          <div class="flex flex-col space-y-1">
            <a
              href="https://airtable.com/app2pzU8coIiyJA2X/shrFqDoWnSMzStob0"
              target="_blank"
              rel="noopener noreferrer"
              class="h-12 px-7.5 md:px-11 md:h-16 spiti-btn-primary-lg"
              >Request access</a
            >
            <!-- <span
              class="text-white text-opacity-60 opacity-60 text-xs md:text-sm text-left"
              >*No credit card required</span
            > -->
          </div>
        </div>
      </div>
      <div
        class="relative z-10 flex flex-col space-y-12 md:space-y-16 lg:space-y-20 items-center justify-center pb-12 md:pb-16 lg:pb-20 marketing-section-inner"
      >
        <div
          class="spiti-text-primary uppercase font-semibold text-base md:text-lg lg:text-xl tracking-widest text-center"
        >
          Powering 150+ future ready teams
        </div>
        <CustomersList class="marketing-section-inner" />
      </div>
    </div>
    <section
      class="relative z-10 px-5 flex flex-col gap-y-4 md:gap-y-0 md:flex-row"
    >
      <div class="md:w-1/2 md:py-10 md:pl-8 md:pr-32 flex md:justify-end">
        <div class="flex flex-col space-y-6 md:space-y-4 md:max-w-md">
          <div class="spiti-heading-title1">
            <span class="spiti-text-daisy-lace">Create playlists,</span>
            <span>not folders</span>
          </div>
          <div
            class="spiti-body-text1 md:flex-grow"
            v-if="!expandProductPanels[1]"
          >
            Organize videos into sequential video playlists, making content easy
            to find and meaningful to watch.
            <br /><br />
            Make your meetings, product demos, onboarding videos, and tech talks
            easily accessible to your team.
          </div>
          <div class="spiti-body-text1 md:flex-grow" v-else>
            Create a playlist for everything important:
            <br /><br />
            <ul class="list-disc list-outside ml-4 lg:ml-6">
              <li>Townhalls</li>
              <li>Customer conversations</li>
              <li>Product demos</li>
              <li>Code walkthroughs</li>
              <li>Employee onboarding...</li>
            </ul>
            <br />
            Easy to access, simple to navigate
          </div>
          <button-show-more :refId="1" :onClick="expandProductPanel" />
        </div>
      </div>
      <div class="md:w-1/2 relative">
        <div
          class="absolute w-full h-full gif-wrapper"
          :style="{
            'max-width': isScreen2xl ? '992px' : `${gifBoxWidth + 80}px`,
          }"
        ></div>
        <div class="z-10 relative p-4 md:p-10 h-full" v-if="!isScreenSm">
          <img
            loading="lazy"
            :src="gifs[0].base"
            class="gif-box"
            :style="{
              height: isScreen2xl ? '570px' : `${gifBoxHeight}px`,
              'min-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
              'max-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
            }"
            :alt="gifs[0].alt"
          />
        </div>
        <div class="z-10 pt-10 relative w-full" v-else>
          <img
            loading="lazy"
            class="gif-box"
            :src="gifs[0].mobile"
            :alt="gifs[0].alt"
          />
        </div>
      </div>
    </section>
    <section
      class="relative z-10 px-5 flex flex-col gap-y-4 md:gap-y-0 md:flex-row"
    >
      <div class="order-last md:order-first md:w-1/2 relative">
        <div class="absolute w-full h-full flex items-end justify-end">
          <div
            class="gif-wrapper transform -rotate-180 w-full h-full"
            :style="{
              'max-width': isScreen2xl ? '992px' : `${gifBoxWidth + 80}px`,
            }"
          ></div>
        </div>
        <div
          class="z-10 relative p-4 md:p-10 h-full flex justify-end"
          v-if="!isScreenSm"
        >
          <img
            loading="lazy"
            :src="gifs[1].base"
            class="gif-box"
            :style="{
              height: isScreen2xl ? '570px' : `${gifBoxHeight}px`,
              'min-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
              'max-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
            }"
            :alt="gifs[1].alt"
          />
        </div>
        <div class="z-10 pt-10 relative w-full" v-else>
          <img
            loading="lazy"
            class="gif-box"
            :src="gifs[1].mobile"
            :alt="gifs[1].alt"
          />
        </div>
      </div>
      <div class="md:w-1/2 md:py-10 md:pr-8 md:pl-32 flex md:justify-start">
        <div class="flex flex-col space-y-6 md:space-y-4 md:max-w-md">
          <div class="spiti-heading-title1">
            <span>Import videos</span>
            <span class="spiti-text-aqua-denim">blazingly fast,</span>
            <span>with integrations</span>
          </div>
          <div
            class="spiti-body-text1 md:flex-grow"
            v-if="!expandProductPanels[2]"
          >
            Natively integrates with Zoom and Google Drive for one-click imports
          </div>
          <div class="spiti-body-text1 md:flex-grow" v-else>
            Get more from your meetings and recorded videos:
            <br /><br />
            <ul class="list-disc list-outside ml-4 lg:ml-6">
              <li>One-click import recorded Zoom meetings</li>
              <li>Bulk import videos from Google Drive</li>
            </ul>
          </div>
          <button-show-more :refId="2" :onClick="expandProductPanel" />
        </div>
      </div>
    </section>
    <section
      class="relative z-10 px-5 flex flex-col gap-y-4 md:gap-y-0 md:flex-row"
    >
      <div class="md:w-1/2 md:py-10 md:pl-8 md:pr-32 flex md:justify-end">
        <div class="flex flex-col space-y-6 md:space-y-4 md:max-w-md">
          <div class="spiti-heading-title1">
            <span class="spiti-text-felicia-daisy">Powerful Search,</span>
            <span>for every word spoken</span>
          </div>
          <div
            class="spiti-body-text1 md:flex-grow"
            v-if="!expandProductPanels[3]"
          >
            Take control of your team videos and get more value from them. Every
            video is auto-transcribed and every word is searchable.
          </div>
          <div class="spiti-body-text1 md:flex-grow" v-else>
            Search anything — from speakers to topics — and auto-navigate to the
            exact location in a video pointing to your search.
          </div>
          <button-show-more :refId="3" :onClick="expandProductPanel" />
        </div>
      </div>
      <div class="md:w-1/2 relative">
        <div
          class="absolute w-full h-full gif-wrapper"
          :style="{
            'max-width': isScreen2xl ? '992px' : `${gifBoxWidth + 80}px`,
          }"
        ></div>
        <div class="z-10 relative p-4 md:p-10 h-full" v-if="!isScreenSm">
          <img
            loading="lazy"
            :src="gifs[2].base"
            class="gif-box"
            :style="{
              height: isScreen2xl ? '570px' : `${gifBoxHeight}px`,
              'min-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
              'max-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
            }"
            :alt="gifs[2].alt"
          />
        </div>
        <div class="z-10 pt-10 relative w-full" v-else>
          <img
            loading="lazy"
            class="gif-box"
            :src="gifs[2].mobile"
            :alt="gifs[2].alt"
          />
        </div>
      </div>
    </section>
    <section
      class="relative z-10 px-5 flex flex-col gap-y-4 md:gap-y-0 md:flex-row"
    >
      <div class="order-last md:order-first md:w-1/2 relative">
        <div class="absolute w-full h-full flex items-end justify-end">
          <div
            class="gif-wrapper transform -rotate-180 w-full h-full"
            :style="{
              'max-width': isScreen2xl ? '992px' : `${gifBoxWidth + 80}px`,
            }"
          ></div>
        </div>
        <div
          class="z-10 relative p-4 md:p-10 h-full flex justify-end"
          v-if="!isScreenSm"
        >
          <img
            loading="lazy"
            :src="gifs[3].base"
            class="gif-box"
            :style="{
              height: isScreen2xl ? '570px' : `${gifBoxHeight}px`,
              'min-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
              'max-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
            }"
            :alt="gifs[3].alt"
          />
        </div>
        <div class="z-10 pt-10 relative w-full" v-else>
          <img
            loading="lazy"
            class="gif-box"
            :src="gifs[3].mobile"
            :alt="gifs[3].alt"
          />
        </div>
      </div>
      <div class="md:w-1/2 md:py-10 md:pr-8 md:pl-32 flex md:justify-start">
        <div class="flex flex-col space-y-6 md:space-y-4 md:max-w-md">
          <div class="spiti-heading-title1">
            <span class="spiti-text-bubblegum-peach">Securely share</span>
            <span>with people outside your team</span>
          </div>
          <div
            class="spiti-body-text1 md:flex-grow"
            v-if="!expandProductPanels[4]"
          >
            Collaborate on the same playlist, from across the city or the world,
            with your team or another team.
          </div>
          <div class="spiti-body-text1 md:flex-grow" v-else>
            <ul class="list-disc list-outside ml-4 lg:ml-6">
              <li>Comment and engage in discussions, privately.</li>
              <li>
                Share and collaborate securely with multiple teams on the same
                playlist.
              </li>
              <li>Share privately with a few members of your own team.</li>
              <li>
                Make a playlist unlisted and share it with anyone on the web. Or
                not. Revoke access whenever you want to.
              </li>
            </ul>
          </div>
          <button-show-more :refId="4" :onClick="expandProductPanel" />
        </div>
      </div>
    </section>
    <section
      class="relative z-10 px-5 flex flex-col gap-y-4 md:gap-y-0 md:flex-row"
    >
      <div class="md:w-1/2 md:py-10 md:pl-8 md:pr-32 flex md:justify-end">
        <div class="flex flex-col space-y-6 md:space-y-4 md:max-w-md">
          <div class="spiti-heading-title1">
            <span class="spiti-text-argo-rose"
              >AI-Generated Summaries, Action Items,</span
            >
            <span>and more</span>
          </div>
          <div
            class="spiti-body-text1 md:flex-grow"
            v-if="!expandProductPanels[5]"
          >
            Maximize your output with the power of AI-generated summaries and
            actionable items. Say goodbye to spending hours sifting through
            lengthy videos.
          </div>
          <div class="spiti-body-text1 md:flex-grow" v-else>
            The AI-Generated Summaries provide concise and comprehensive
            summaries, capturing the essence of your content. With the
            flexibility to edit, copy, and regenerate these Summaries and Action
            Items, you can fine-tune the output.
          </div>
          <button-show-more :refId="5" :onClick="expandProductPanel" />
        </div>
      </div>
      <div class="md:w-1/2 relative">
        <div
          class="absolute w-full h-full gif-wrapper"
          :style="{
            'max-width': isScreen2xl ? '992px' : `${gifBoxWidth + 80}px`,
          }"
        ></div>
        <div class="z-10 relative p-4 md:p-10 h-full" v-if="!isScreenSm">
          <img
            loading="lazy"
            :src="gifs[4].base"
            class="gif-box"
            :style="{
              height: isScreen2xl ? '570px' : `${gifBoxHeight}px`,
              'min-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
              'max-width': isScreen2xl ? '912px' : `${gifBoxWidth}px`,
            }"
            :alt="gifs[4].alt"
          />
        </div>
        <div class="z-10 pt-10 relative w-full" v-else>
          <img
            loading="lazy"
            class="gif-box"
            :src="gifs[4].mobile"
            :alt="gifs[4].alt"
          />
        </div>
      </div>
    </section>
    <section class="marketing-section">
      <div class="marketing-section-inner flex flex-col">
        <span class="spiti-heading-title1 text-center"
          >Loved by people at small and large teams</span
        >
        <div class="relative">
          <span
            class="absolute -top-8 md:-top-24 lg:-top-36 -left-24 left-quotes"
            >“</span
          >
          <div
            class="mt-12 md:mt-16 lg:mt-20 grid grid-flow-col auto-cols-max gap-6 justify-items-center justify-center items-center"
          >
            <div
              class="w-max flex flex-col items-center space-y-4 md:space-y-6"
            >
              <div
                class="feedback-box p-4 pr-6 flex flex-row space-x-3 md:space-x-4"
                v-for="customer of feedbackGrid[0]"
                :key="customer.name"
              >
                <img
                  loading="lazy"
                  :src="customer.picture"
                  :alt="customer.alt"
                  class="h-7 w-7 rounded-full md:h-9 md:w-9"
                />
                <div class="flex flex-col space-y-2 md:space-y-4">
                  <div class="flex flex-col space-y-1">
                    <span class="spiti-body-subtext1 text-white font-bold">{{
                      customer.name
                    }}</span>
                    <span class="spiti-body-metatext customer-tag">{{
                      customer.tag
                    }}</span>
                  </div>
                  <span class="spiti-body-metatext text-white">{{
                    customer.text
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="w-max hidden md:flex flex-col items-center pt-6 space-y-4 md:space-y-6"
            >
              <div
                class="feedback-box p-4 pr-6 flex flex-row space-x-3 md:space-x-4"
                v-for="customer of feedbackGrid[1]"
                :key="customer.name"
              >
                <img
                  loading="lazy"
                  :src="customer.picture"
                  :alt="customer.alt"
                  class="h-7 w-7 rounded-full md:h-9 md:w-9"
                />
                <div class="flex flex-col space-y-2 md:space-y-4">
                  <div class="flex flex-col space-y-1">
                    <span class="spiti-body-subtext1 text-white font-bold">{{
                      customer.name
                    }}</span>
                    <span class="spiti-body-metatext customer-tag">{{
                      customer.tag
                    }}</span>
                  </div>
                  <span class="spiti-body-metatext text-white">{{
                    customer.text
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="w-max hidden lg:flex flex-col items-center pt-12 space-y-4 md:space-y-6"
            >
              <div
                class="feedback-box p-4 pr-6 flex flex-row space-x-3 md:space-x-4"
                v-for="customer of feedbackGrid[2]"
                :key="customer.name"
              >
                <img
                  loading="lazy"
                  :src="customer.picture"
                  :alt="customer.alt"
                  class="h-7 w-7 rounded-full md:h-9 md:w-9"
                />
                <div class="flex flex-col space-y-2 md:space-y-4">
                  <div class="flex flex-col space-y-1">
                    <span class="spiti-body-subtext1 text-white font-bold">{{
                      customer.name
                    }}</span>
                    <span class="spiti-body-metatext customer-tag">{{
                      customer.tag
                    }}</span>
                  </div>
                  <span class="spiti-body-metatext text-white">{{
                    customer.text
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <span
            class="absolute -right-24 -bottom-8 md:-bottom-24 lg:-bottom-44 right-quotes"
            >“</span
          >
          <div class="mt-16 md:mt-20 lg:mt-24 flex items-center justify-center">
            <nuxt-link
              class="flex flex-row space-x-2 items-center"
              to="/customers"
            >
              <span class="spiti-text-primary spiti-heading-title2"
                >See our latest case studies</span
              >
              <img src="~/assets/img/icons/arrow-right-primary.svg" />
            </nuxt-link>
          </div>
        </div>
      </div>
    </section>
    <section class="marketing-section">
      <div
        class="marketing-section-inner flex flex-col space-y-20 sm:space-y-24 md:space-y-28 lg:space-y-32 items-center text-center"
      >
        <span class="spiti-heading-title1">Get your team started today</span>
        <fearues-box-with-c-t-a />
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import FearuesBoxWithCTA from "~/components/marketing/FeaturesBoxWithCTA.vue";
import CustomersList from "~/components/marketing/CustomersList.vue";
import ButtonShowMore from "~/components/marketing/ButtonShowMore.vue";
import SparklesPulse from "~/components/marketing/SparklesPulse";

export default {
  components: {
    FearuesBoxWithCTA,
    ButtonShowMore,
    CustomersList,
    SparklesPulse,
  },
  computed: {
    ...mapGetters(["isMarketingMenuOpen"]),
    feedbackGrid: function () {
      if (this.isScreenLg) {
        return [
          [this.feedback[0], this.feedback[1]],
          [this.feedback[2], this.feedback[3]],
          [this.feedback[4], this.feedback[5]],
        ];
      }
      if (this.isScreenMd) {
        return [
          [this.feedback[0], this.feedback[2]],
          [this.feedback[4], this.feedback[5]],
          [],
        ];
      }
      return [[this.feedback[0], this.feedback[2], this.feedback[4]], [], []];
    },
  },
  mounted() {
    this.computeScreenSize();
    this.computeGifBoxDimensions();
  },
  data() {
    return {
      gifOffsetWidthRatio: 168 / 912,
      gifBoxRatio: 912 / 570,
      gifBoxWidth: 0,
      gifBoxHeight: 0,
      isScreenSm: false,
      isScreenMd: false,
      isScreenLg: false,
      isScreenXl: false,
      isScreen2xl: false,
      expandProductPanels: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      expandGifSections: {
        1: false,
        2: false,
        3: false,
        4: false,
      },
      gifs: [
        {
          base: require("~/assets/img/gifs/create-playlists.gif"),
          mobile: require("~/assets/img/gifs/create-playlists-sm.jpg"),
          alt: "Spiti's customer conversations with Amazon and DeepSource stored and organized in a video playlist on Spiti, a private video library for high performing teams. Also shown are Spiti applications's code walkthroughs and code reviews on Zoom stored and organized in a video playlist on Spiti, a private video library for high performing teams.",
        },
        {
          base: require("~/assets/img/gifs/import-videos.gif"),
          mobile: require("~/assets/img/gifs/import-videos-sm.jpg"),
          alt: "Importing Zoom meeting recordings from Zoom cloud and Google Meet recordings from Google Drive into Spiti, a private video library for high performing teams.",
        },
        {
          base: require("~/assets/img/gifs/search.gif"),
          mobile: require("~/assets/img/gifs/search-sm.jpg"),
          alt: "Spiti's customer conversations with Amazon and DeepSource stored and organized in a video playlist on Spiti, a private video library for high performing teams. Also shown are Spiti applications's code walkthroughs and code reviews on Zoom stored and organized in a video playlist on Spiti, a private video library for high performing teams.",
        },
        {
          base: require("~/assets/img/gifs/spiti-connect.gif"),
          mobile: require("~/assets/img/gifs/spiti-connect-sm.jpg"),
          alt: "Introduction to AWS Cloud Computing by Spiti's engineering team shared with companies Nykaa, Last9 and DeepSource on Spiti, a private video library for high performing teams.",
        },
        {
          base: require("~/assets/img/gifs/ai-insights.gif"),
          mobile: require("~/assets/img/gifs/ai-insights-sm.png"),
          alt: "Spiti's customer conversations with Amazon and DeepSource stored and organized in a video playlist on Spiti, a private video library for high performing teams. Also shown are Spiti applications's code walkthroughs and code reviews on Zoom stored and organized in a video playlist on Spiti, a private video library for high performing teams.",
        },
      ],
      feedback: [
        {
          name: "Ilayraja",
          tag: "Director of Engineering, Nykaa",
          picture: require("~/assets/img/customers/ilayaraja.png"),
          alt: "Ilayraja, Director of Engineering at Nykaa",
          text: '"I wanted a central platform that is fast, easy on the eyes, simple to use and solves our video access and sharing problems."',
        },
        {
          name: "Shivam",
          tag: "Engineering Lead, DeepSource",
          picture: require("~/assets/img/customers/shivam.png"),
          alt: "Shivam, Engineering Lead at DeepSource",
          text: '"Introducing Spiti to the team has made my job as a developer and mentor much easier, it\'s really easy to revisit old product meetings, brown bag sessions and code walkthroughs."',
        },
        {
          name: "Brandon",
          tag: "Head of Sales, Fluix",
          picture: require("~/assets/img/customers/brandon.png"),
          alt: "Brandon, Head of Sales at Fluix",
          text: '"If you’re looking for a way to create your own internal video channel with your team, give Spiti a careful look. So many other alternatives take too much time to implement or limit your ability to control who sees which videos. Spiti is easy to learn and rewarding to use."',
        },
        {
          name: "Kushal",
          tag: "Partner, All in Capital",
          picture: require("~/assets/img/investors/kushal.png"),
          alt: "Kushal, Partner at All in Capital",
          text: '"After having seen multiple portfolio companies struggle with organising, curating, finding and consuming the hundreds of hours of video content they create, I couldn\'t be more excited about Spiti!"',
        },
        {
          name: "Sanket",
          tag: "Founder, DeepSource",
          picture: require("~/assets/img/customers/sanket.png"),
          alt: "Sanket, Founder of DeepSource",
          text: '"Our team went more asynchronous, we realized we were producing more and more videos but completely lacked a central place to store them. We started using Spiti to organize all these videos in a single place, and it worked beautifully."',
        },
        {
          name: "Harry",
          tag: "Founder, WTF",
          picture: require("~/assets/img/customers/harry.jpeg"),
          alt: "Harry, Founder of WTF",
          text: '"This is a no-brainer of sorts for any team. At WTF, Spiti has simplified cataloguing our remote product demos. Especially the integrations with Zoom and Slack has been powerful."',
        },
      ],
    };
  },
  methods: {
    computeGifBoxDimensions() {
      const screenWidth = window.innerWidth;
      if (this.isScreenMd || this.isScreenLg) {
        this.gifBoxWidth =
          (screenWidth / 2 - 40) / (1 - this.gifOffsetWidthRatio);
        this.gifBoxHeight = this.gifBoxWidth / this.gifBoxRatio;
      } else {
        this.gifBoxWidth = screenWidth;
        this.gifBoxHeight = this.gifBoxWidth / this.gifBoxRatio;
      }
    },
    computeScreenSize() {
      const screenWidth = window.innerWidth;

      this.isScreen2xl = screenWidth >= 1536;

      if (screenWidth > 1024) {
        this.isScreenLg = true;
        return;
      }
      if (screenWidth > 768) {
        this.isScreenMd = true;
        return;
      }
      this.isScreenSm = true;
    },
    expandProductPanel(index) {
      Vue.set(
        this.expandProductPanels,
        index,
        !this.expandProductPanels[index]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  @apply border-b border-white border-opacity-15 py-20 sm:py-24 md:py-28 lg:py-32;
}

.splash-header {
  @media screen and (min-width: 768px) {
    font-size: 64px;
  }
  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 38.4px;
  }
}
.header-gradient-ellipse1 {
  width: 75%;
  height: 75%;
  margin-top: -25%;
  margin-left: -25%;
  background: linear-gradient(
    133.16deg,
    rgba(102, 86, 173, 0.5) 35.9%,
    rgba(160, 142, 238, 0.085) 66.05%,
    rgba(160, 142, 238, 0) 107.09%
  );

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-filter: blur(100px);
  filter: blur(100px);
}

.header-gradient-ellipse2 {
  width: 75%;
  height: 75%;
  margin-right: -50%;
  margin-bottom: -50%;
  background: linear-gradient(
    33.16deg,
    rgba(102, 86, 173, 0.35) 35.9%,
    rgba(160, 142, 238, 0.0595) 66.05%,
    rgba(160, 142, 238, 0) 107.09%
  );

  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -webkit-filter: blur(100px);
  filter: blur(100px);
  // transform: rotate(142.8deg);
}

.collab-section-gradient {
  background: linear-gradient(90deg, #0b1821 0%, #16111d 100%);
}

.gif-wrapper {
  background: linear-gradient(
    265.99deg,
    rgba(160, 142, 238, 0.5) 2.71%,
    rgba(63, 52, 114, 0.5) 89.78%
  );
  mix-blend-mode: difference;
  opacity: 0.5;
  -webkit-filter: blur(50px);
  filter: blur(50px);
}

.gif-box {
  box-sizing: border-box;
  border: 0.3px solid rgba(255, 255, 255, 0.3);
  box-shadow: 10px 10px 100px 20px rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

.right-quotes {
  width: 180px;
  height: 375px;
  font-weight: 600;
  font-size: 400px;
  line-height: 165%;
  @media (max-width: 768px) {
    font-size: 160px;
    line-height: 264px;
    height: 150px;
  }
  text-align: center;
  color: #a08eee;
  opacity: 0.15;
  transform: rotate(-180deg);
}

.left-quotes {
  width: 180px;
  height: 405px;
  font-weight: 600;
  font-size: 400px;
  line-height: 165%;
  @media (max-width: 768px) {
    font-size: 160px;
    line-height: 264px;
    height: 150px;
  }
  text-align: center;
  color: #a08eee;
  opacity: 0.15;
  transform: matrix(1, 0, 0, -1, 0, 0);
}

.feedback-box {
  width: 360px;
  height: 254px;

  @media (max-width: 768px) {
    width: 320px;
    height: max-content;
  }

  background: linear-gradient(
    180deg,
    rgba(170, 170, 170, 0.2) 0%,
    rgba(203, 203, 203, 0.06) 100%
  );
  border-radius: 20px;
}

.customer-tag {
  color: #9a9a9a;
}

.animate-sec1 {
  @media (min-width: 768px) {
    animation-name: fadeInBottom;
    animation-duration: 1.5s;
    animation-delay: 0s;
    transition-timing-function: ease-out;
    animation-fill-mode: both;
  }
}

.animate-sec2 {
  @media (min-width: 768px) {
    animation-name: fadeInBottom;
    animation-duration: 1.5s;
    animation-delay: 0.4s;
    transition-timing-function: ease-out;
    animation-fill-mode: both;
  }
}

.animate-sec3 {
  @media (min-width: 768px) {
    animation-name: fadeInBottom;
    animation-duration: 1.5s;
    animation-delay: 0.6s;
    transition-timing-function: ease-out;
    animation-fill-mode: both;
  }
}
</style>
