<template>
  <button
    @mouseenter="onMouseEnter()"
    @mouseleave="onMouseLeave()"
    @mousedown="onMouseDown()"
    @mouseup="onMouseUp()"
    @click="toggle()"
    class="w-max flex flex-row items-center justify-center space-x-2 md:space-x-4"
  >
    <img
      v-if="pressed && state === BTN_STATES.MORE"
      class="w-4 md:w-6"
      src="~/assets/img/icons/marketing/plus-circle/primary-active.svg"
    />
    <img
      v-else-if="pressed && state === BTN_STATES.CLOSE"
      class="w-4 md:w-6"
      src="~/assets/img/icons/marketing/close-circle/primary-active.svg"
    />
    <img
      v-else-if="hovered && state === BTN_STATES.MORE"
      class="w-4 md:w-6"
      src="~/assets/img/icons/marketing/plus-circle/primary-hover.svg"
    />
    <img
      v-else-if="hovered && state === BTN_STATES.CLOSE"
      class="w-4 md:w-6"
      src="~/assets/img/icons/marketing/close-circle/primary-hover.svg"
    />
    <img
      v-else-if="state === BTN_STATES.MORE"
      class="w-4 md:w-6"
      src="~/assets/img/icons/marketing/plus-circle/primary.svg"
    />
    <img
      v-else-if="state === BTN_STATES.CLOSE"
      class="w-4 md:w-6"
      src="~/assets/img/icons/marketing/close-circle/primary.svg"
    />
    <span
      class="capitalize"
      :class="{
        'btn-text-hover': hovered,
        'btn-text-active': pressed,
        'btn-text': !hovered && !pressed,
      }"
      >{{ state }}</span
    >
  </button>
</template>

<script>
const BTN_STATES = {
  MORE: "more",
  CLOSE: "close",
};

export default {
  props: {
    refId: {
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      BTN_STATES,
      hovered: false,
      pressed: false,
      state: BTN_STATES.MORE,
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
  },
  methods: {
    onMouseEnter() {
      if (this.isMobile) return;
      this.hovered = true;
    },
    onMouseLeave() {
      if (this.isMobile) return;
      this.hovered = false;
    },
    onMouseDown() {
      if (this.isMobile) return;
      this.pressed = true;
    },
    onMouseUp() {
      if (this.isMobile) return;
      this.pressed = false;
    },
    toggle() {
      this.state =
        this.state === BTN_STATES.MORE ? BTN_STATES.CLOSE : BTN_STATES.MORE;
      this.onClick(this.refId);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-text {
  background: linear-gradient(135.82deg, #a08eee 0%, #3f3472 157.78%);
  @apply font-semibold text-base md:text-lg lg:text-xl bg-clip-text text-transparent;
}

.btn-text-hover {
  background: linear-gradient(135.5deg, #907ce3 0%, #322764 160.77%);
  @apply font-semibold text-base md:text-lg lg:text-xl bg-clip-text text-transparent;
}

.btn-text-active {
  background: linear-gradient(135.5deg, #9683e7 0%, #5c4da3 160.77%);
  @apply font-semibold text-base md:text-lg lg:text-xl bg-clip-text text-transparent;
}
</style>
